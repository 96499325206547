import { render, staticRenderFns } from "./companyInfo.vue?vue&type=template&id=3b5703e2&scoped=true&"
import script from "./companyInfo.vue?vue&type=script&lang=js&"
export * from "./companyInfo.vue?vue&type=script&lang=js&"
import style0 from "./companyInfo.vue?vue&type=style&index=0&id=3b5703e2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5703e2",
  null
  
)

export default component.exports